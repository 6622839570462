
.open-answer {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  textarea {
    width: 100%;
    min-height: 150px;
    padding: 0px;
    font-family: sans-serif;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical;
    transition: border-color 0.3s ease;


    &:disabled {
      background-color: #e9ecef;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #6c757d;
    }
  }

.question-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.question-title {
    font-size: 2.5rem;
    color: #500000;
    text-align: center;
    margin-bottom: 1rem;
}

.question-text {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.answer-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
}
.answer-item {
    padding: 1rem;
    background-color: #fff;
    border: 2px solid #500000;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.answer-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: 0.5s;
}

.answer-item:hover:not(:disabled)::before {
    left: 100%;
}

.answer-item:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.answer-item.selected {
    background-color: #500000;
    color: #fff;
}

.answer-item.selected:hover:not(:disabled) {
    background-color: #3d0000;
    border-color: #3d0000;
}

.answer-item.correct {
    background-color: #4caf50;
    color: #fff;
    border-color: #4caf50;
}


.answer-item.incorrect {
    background-color: #f44336;
    color: #fff;
    border-color: #f44336;
}


.answer-item:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.answer-item:not(.selected):not(.correct):not(.incorrect) {
    color: #333;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: #500000;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover:not(:disabled) {
    background-color: #3d0000;
}


.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.result {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1.1rem;
    text-align: center;
}

.result.correct {
    background-color: #e8f5e9;
    color: #4caf50;
}

.result.correct:hover {
    background-color: #4caf50;;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.result.incorrect {
    background-color: #ffebee;
    color: #f44336;
    cursor: pointer;
    transition: all 0.3s ease;
}

.result.incorrect:hover {
    background-color: #ff6b6b;
    color: white;
}

.open-question-answer {
    color: rgba(195, 18, 18, 0.653);
}


.question-loading,
.question-error,
.question-not-found {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #333;
}
