.error {
    color: #d32f2f;
}

.courses-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
    list-style-type: none;
}

.course-item {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 15px;
    height: 120px; 
}

.course-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.course-item-header {
    background-color: #500000;
    color: #ffffff;
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.course-item-name {
    font-size: 1.1em;
    color: #333;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight:bold;
}

.course-item-buttoms {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    opacity: 0;
    transition: opacity 0.5s ease; 
}

.course-item:hover .course-item-buttoms {
    opacity: 1;
}

.course-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
    color: rgba(80, 0, 0, 0.9);
    border: 2px solid transparent;
}

.course-button:hover {
    opacity: 1;
    border-color: rgba(80, 0, 0, 0.9);
    border-radius: 8px;
}

.course-button.active {
    text-decoration: underline;
}