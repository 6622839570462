.footer {
    flex-shrink: 0;
    background-color: #500000;
    color: white;
    padding: 10px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    margin: 1rem;
}

.footer-section h3 {
    margin-bottom: 1rem;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section a, .footer-bottom a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section a:hover, .footer-bottom a:hover {
    color: #e0e0e0;
}

.footer-bottom {
    text-align: center;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}