.resource-list {
    list-style-type: none;
    padding: 1rem;
    background-color: #ffffff; 
}

.resource-list-item {
    margin-bottom: 0.5rem;
}

.resource-list-item a {
    display: block;
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-decoration: none;
    color: #500000;
    transition: background-color 0.3s ease;
}

