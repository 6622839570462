.result {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1.1rem;
    text-align: center;
    cursor: pointer;
    background-color: #ccc;
    color: white;
    transition: all 0.3s ease;
}

.result:hover{
    background-color: #e4e2e2;
    color: #500000;
}

.result.correct {
    background-color: #e8f5e9;
    color: #4caf50;
}

.result.correct:hover {
    background-color: #4caf50;
    color: white;
}

.result.incorrect {
    background-color: #ffebee;
    color: #f44336;
}

.result.incorrect:hover {
    background-color: #ff6b6b;
    color: white;
}