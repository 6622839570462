.exam-list {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
}

.exam-list-title {
    color: #500000;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    padding-bottom: 0.5rem;
}

.loading, .error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 2rem auto;
    max-width: 600px;
}

.error {
    color: #d32f2f;
    border: 1px solid #d32f2f;
}

.exam-item {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
}

.exam-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exam-item h3 {
    color: #500000;
    margin-bottom: 0.5rem;
}

.exam-item p {
    color: #666;
    font-size: 0.9rem;
}

.exam-link {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #500000;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.exam-link:hover {
    background-color: #3d0000;
}

@media (max-width: 768px) {
    .exam-list {
        padding: 1rem;
        margin: 1rem;
    }

    .exam-list-title {
        font-size: 1.5rem;
    }
}