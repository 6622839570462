.open-answer {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

textarea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    font-family: sans-serif;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical;
    transition: border-color 0.3s ease;
}

textarea:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

textarea::placeholder {
    color: #6c757d;
}

.open-question-answer {
    color: rgba(195, 18, 18, 0.653);
}