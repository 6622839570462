@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

* {
  font-family: 'Bitter', sans-serif;
}

body {
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; 
}

.light-text {
  font-weight: 300;
}