.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #500000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
    font-size: 1.5em;
    font-weight: bold;
}

.navbar-brand-link {
    color: #fff;
    text-decoration: none;
    transition: transform 0.3s ease;
}

.navbar-brand-link:hover {
    transform: scale(1.1);
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navbar-link {
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease, transform 0.3s ease, border-bottom 0.3s ease;
    position: relative;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
}

.navbar-link:hover {
    color: #f9f9f9;
    transform: translateY(-2px);
    border-bottom: 2px solid #f9f9f9;
}

.active {
    border-bottom: 2px solid #f9f9f9;
}