.in-development-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.in-development-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.in-development-header {
    color: #500000;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.in-development-content h2 {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.5;
}

.close-button {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: #500000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1rem;
    font-weight: 500;
}

.close-button:hover {
    background-color: #3d0000;
}