.courses-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.courses-title, .course-search-title {
    font-size: 2.5rem;
    color: rgba(80, 0, 0, 0.9);
    text-align: center;
    margin-bottom: 1rem;
}

.loading, .error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #333;
}

.page-container {
    display: flex;
    flex-direction: column;
}

.content-wrap {
    flex: 1;
}