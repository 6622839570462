.question-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.question-link {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 2px solid #500000;
    border-radius: 4px;
    color: #500000;
    text-decoration: none;
    transition: all 0.3s ease;
}

.question-link:hover {
    background-color: #500000;
    color: #fff;
}

.question-link-active {
    background-color: #500000;
    color: #fff;
}

.question-link.correct {
    background-color: #4caf50;
    color: #fff;
    border-color: #4caf50;
}

.question-link.incorrect {
    background-color: #f44336;
    color: #fff;
    border-color: #f44336;
}

.question-link-active.correct,
.question-link-active.incorrect {
    background-color: #500000;
    color: #fff;
    border-color: #500000;
}